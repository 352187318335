













































































import { Vue, Component, Prop } from "vue-property-decorator";

import { SvgIcon, Loader } from "@/components";
import { Batch } from "@/proto/batch/all_pb";
import { unixToDateString, formatCurrency } from "@/utils/common";
import { OrgAccount } from "@/types";

@Component({
  components: {
    SvgIcon,
    Loader,
  },
})
export default class SingleTxnModal extends Vue {
  @Prop({ default: false })
  resending!: boolean;

  @Prop()
  batch!: Batch.AsObject;

  @Prop()
  accounts!: Array<OrgAccount>;

  get txn() {
    const txn = this.batch.transactionsList[0];
    const amount = parseFloat(txn?.amount?.num as string);

    return {
      destinationAccountName: txn?.destinationaccount?.ownername || "N/A",
      destinationAccountID: txn?.destinationaccount?.accountid || "N/A",
      sourceAccountID: txn?.sourceaccount?.accountid,
      sourceAccountName: txn?.sourceaccount?.ownername || "N/A",
      settlementrail: txn?.settlementrail,
      date: unixToDateString(this.batch?.created?.seconds),
      ref: this.batch?.id,
      remarks: this.batch?.purpose,
      amount: formatCurrency(amount / 100),
    };
  }

  get isProcessed() {
    return this.batch.status === "Processed";
  }

  get isProcessing() {
    return this.batch.status === "Processing";
  }

  get isPending() {
    return this.batch.status === "Created";
  }

  get isApproved() {
    return this.batch.status === "Approved";
  }

  get availableBalance() {
    const txn = this.batch.transactionsList[0];
    const sourceAccount: OrgAccount = this.accounts.find(
      (acc) => acc.accountNumber === txn.sourceaccount?.accountid
    ) as OrgAccount;

    if (sourceAccount.availableBalance) {
      return formatCurrency(sourceAccount.availableBalance);
    }

    return formatCurrency(0);
  }
}
