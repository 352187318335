
















































































import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import VModal from "vue-js-modal";

Vue.use(VModal);

import {
  CustomTable,
  CustomInput,
  SvgIcon,
  ApproveBatchModal,
  RejectBatchModal,
  ProcessedBatchModal,
  OTPModal,
} from "@components";
import SingleTxnModal from "./SingleTxnModal.vue";
import {
  unixToDateString,
  getBatchStatusLabel,
  formatCurrency,
} from "@/utils/common";
import { ListBatchResponse, Batch } from "@/proto/batch/all_pb";
import { OrgAccount } from "@/types";

const transactionsModule = namespace("transactions");
const orgModule = namespace("org");

interface TFAPayload {
  code: string;
  batchID: string;
}

@Component({
  components: {
    CustomTable,
    CustomInput,
    SvgIcon,
    SingleTxnModal,
    ApproveBatchModal,
    RejectBatchModal,
    ProcessedBatchModal,
    OTPModal,
  },
})
export default class SingleTxnsTable extends Vue {
  @orgModule.State("accounts") accounts!: OrgAccount[];

  @transactionsModule.Action("getSingleTxns")
  getSingleTxns!: () => ListBatchResponse.AsObject;
  @transactionsModule.Action("processTFA") processTFA!: (
    payload: TFAPayload
  ) => void;
  @transactionsModule.Action("resendTFA") resendTFA!: (batchID: string) => void;

  searchTerm = "";
  otpError = "";
  error = "";
  resending = false;
  selectedBatch: Batch.AsObject = {} as Batch.AsObject;
  singleTxns: Array<Batch.AsObject> = [];

  get columns() {
    return [
      {
        field: "transaction",
        label: "Transaction",
        sortable: false,
      },
      {
        field: "reference",
        label: "Reference",
        sortable: false,
      },
      {
        field: "createdDate",
        label: "Date",
        sortable: false,
      },
      {
        field: "amount",
        label: "Amount",
        sortable: false,
      },
      {
        field: "status",
        label: "Status",
        sortable: false,
      },
    ];
  }

  get rows() {
    // If currently empty just explicitly return an empty array
    if (!this.singleTxns.length) {
      return [];
    }

    // Remove any empty transactionList
    let singleTxns = this.singleTxns.filter((txn) => {
      return txn.transactionsList.length !== 0;
    });

    // Sort single batch txn by created date
    singleTxns = singleTxns.sort((a, b) => {
      const ac = a.created.seconds;
      const bc = b.created.seconds;

      return ac > bc ? -1 : ac < bc ? 1 : 0;
    });

    return singleTxns.map((batch) => {
      const txn = batch.transactionsList[0];
      const statusLabel = getBatchStatusLabel(batch.status);
      const statusLabelClass = `batch-${statusLabel.toLowerCase()}`;

      return {
        transaction: "Fund Transfer",
        reference: batch.id,
        createdDate: this._txnCreatedDate(batch.created.seconds),
        status: statusLabel,
        statusClass: statusLabelClass,
        amount: formatCurrency(parseFloat(txn.amount?.num as string) / 100),
        actions: "",
      };
    });
  }

  mounted() {
    this.fetchTxns();
  }

  async fetchTxns() {
    const result = await this.getSingleTxns();
    this.singleTxns = result.batchList;
  }

  onRowClick(params: Record<string, any>) {
    const batch = this.singleTxns.find(
      (batch) => batch.id === params.row.reference
    ) as Batch.AsObject;

    this.selectedBatch = batch;
    this.$modal.show("single-txn-modal");
  }

  closeSingleTxnModal() {
    this.$modal.hide("single-txn-modal");
  }

  onApproveBatch() {
    this.closeSingleTxnModal();
    this.$modal.show("approve-txn-modal");
  }

  onCancelApproval() {
    this.$modal.hide("approve-txn-modal");
  }

  onCancelReject() {
    this.$modal.hide("reject-txn-modal");
  }

  onConfirmReject() {
    this.$modal.hide("reject-txn-modal");
    this.fetchTxns();
  }

  onConfirmApproval() {
    this.fetchTxns();
    this.$modal.hide("approve-txn-modal");
    this.showOTPModal();
  }

  onProcessedModalClose() {
    this.$modal.hide("processed-txn-modal");
  }

  onOTPModalClose() {
    this.$modal.hide("otp-modal");
  }

  async onOTPCodeSubmit(code: string) {
    try {
      await this.processTFA({
        code: code,
        batchID: this.selectedBatch.id,
      });
      this.$modal.hide("otp-modal");
      this.$modal.show("processed-txn-modal");
    } catch (error) {
      const err = error as Error;
      this.otpError = err.message;
    }

    this.fetchTxns();
  }

  async onResendOTP() {
    this.resending = true;

    try {
      await this.resendTFA(this.selectedBatch.id);
      this.closeSingleTxnModal();
      this.showOTPModal();
    } catch (error) {
      const err = error as Error;
      this.error = err.message;
    } finally {
      this.resending = false;
    }
  }

  showOTPModal() {
    this.otpError = "";
    this.$modal.show("otp-modal");
  }

  onRejectBatch() {
    this.closeSingleTxnModal();
    this.$modal.show("reject-txn-modal");
  }

  _txnCreatedDate(createdDate: number): string {
    if (!createdDate) return "N/A";
    return unixToDateString(createdDate);
  }
}
