

















import { Vue, Component } from "vue-property-decorator";

import { Tabs, TabItem } from "@/components";
import BatchTxnsTable from "./components/BatchTxnsTable.vue";
import SingleTxnsTable from "./components/SingleTxnsTable.vue";

@Component({
  components: {
    Tabs,
    TabItem,
    BatchTxnsTable,
    SingleTxnsTable,
  },
})
export default class BatchManagement extends Vue {}
