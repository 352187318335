





































import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { CustomTable, CustomInput, SvgIcon } from "@components";
import {
  unixToDateString,
  getBatchStatusLabel,
  formatCurrency,
} from "@/utils/common";
import { Batch, ListBatchResponse } from "@/proto/batch/all_pb";
import { Transaction } from "@/proto/transactions/all_pb";

const transactionsModule = namespace("transactions");

@Component({
  components: {
    CustomTable,
    CustomInput,
    SvgIcon,
  },
})
export default class BatchTxnsTable extends Vue {
  @transactionsModule.Action("getBatchTxns")
  getBatchTxns!: (batch: Array<string>) => ListBatchResponse.AsObject;

  searchTerm = "";
  columns = {};
  batchTxns: Array<Batch.AsObject> = [];

  mounted() {
    this.fetchBatchTxns();
  }

  async fetchBatchTxns() {
    const result = await this.getBatchTxns([]);
    this.batchTxns = result.batchList;
  }

  get rows() {
    // If currently empty just explicitly return an empty array
    if (!this.batchTxns.length) {
      return [];
    }

    // Remove any empty transactionList
    let batchTxns = this.batchTxns.filter((batch) => {
      return batch.transactionsList.length !== 0;
    });

    // Sort single batch txn by created date
    batchTxns = batchTxns.sort((a, b) => {
      const ac = a.created.seconds;
      const bc = b.created.seconds;

      return ac > bc ? -1 : ac < bc ? 1 : 0;
    });

    return batchTxns.map((batch) => {
      const statusLabel = getBatchStatusLabel(batch.status);
      const statusLabelClass = `batch-${statusLabel.toLowerCase()}`;

      return {
        transaction: "Batch Debit",
        reference: batch.id,
        createdDate: this._batchCreatedDate(batch.created.seconds),
        status: statusLabel,
        statusClass: statusLabelClass,
        amount: this._getTotalAmount(batch.transactionsList),
        actions: "",
      };
    });
  }

  _batchCreatedDate(createdDate: number): string {
    if (!createdDate) return "N/A";
    return unixToDateString(createdDate);
  }

  _getTotalAmount(txnList: Array<Transaction.AsObject>) {
    let totalAmt = 0;

    txnList.forEach((txn) => {
      totalAmt += parseFloat(txn.amount?.num as string);
    });

    return formatCurrency(totalAmt / 100);
  }

  onRowClick(params: Record<string, any>) {
    this.$router.push({
      path: `/batches/${params.row.reference}`,
    });
  }

  created() {
    this.columns = [
      {
        field: "transaction",
        label: "Transaction",
        sortable: false,
      },
      {
        field: "reference",
        label: "Reference",
        sortable: false,
      },
      {
        field: "createdDate",
        label: "Created Date",
        sortable: false,
      },
      {
        field: "amount",
        label: "Amount",
        sortable: false,
      },
      {
        field: "status",
        label: "Status",
        sortable: false,
      },
    ];
  }
}
